import React from 'react';
import { MessagesSquare, HeadphonesIcon } from 'lucide-react';

export const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-800 py-6">
            <div className="flex flex-col items-center gap-6">
                <div className="flex flex-col sm:flex-row items-center gap-4 w-full justify-center">
                    <a
                        href="https://t.me/BubShopBot"
                        className="flex items-center gap-2 px-6 py-3 bg-[#25303B] rounded-xl hover:bg-[#2b3845] transition-all duration-300 text-white group w-full sm:w-auto"
                    >
                        <MessagesSquare className="h-5 w-5 text-blue-400 group-hover:scale-110 transition-transform duration-300" />
                        <div className="flex flex-col">
                            <span className="text-sm text-gray-400">Оформление заказа</span>
                            <span className="font-medium">@BubShopBot</span>
                        </div>
                    </a>

                    <a
                        href="https://t.me/Bubs_Shop_Support"
                        className="flex items-center gap-2 px-6 py-3 bg-[#25303B] rounded-xl hover:bg-[#2b3845] transition-all duration-300 text-white group w-full sm:w-auto"
                    >
                        <HeadphonesIcon className="h-5 w-5 text-green-400 group-hover:scale-110 transition-transform duration-300" />
                        <div className="flex flex-col">
                            <span className="text-sm text-gray-400">Техническая поддержка</span>
                            <span className="font-medium">@Bubs_Shop_Support</span>
                        </div>
                    </a>
                </div>

                <div className="text-gray-500 text-sm font-medium border-t border-gray-800 pt-6 mt-2 w-full text-center">
                    {new Date().getFullYear()} © BubsShop
                </div>

                {/* Company information - not indexed by search engines */}
                <div aria-hidden="false" className="text-gray-500 text-xs w-full text-center -mt-4">
                    <span className="block">Информация о юрлице: GEARUP PORTAL PTE. LTD.</span>
                    <span className="block">reg.Number 202237112N</span>
                    <span className="block">1 RAFFLES QUAY #49 SINGAPORE</span>
                </div>
            </div>
        </footer>
    );
};